<template>
    <router-view />
</template>

<script setup >

</script>
<script>
export default {
    name: "outSide"
}
</script>

<style></style>