import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import defAva from '@/assets/images/profile.jpg'

const useUserStore = defineStore(
  'user',
  {
    // persist: {
    //   enabled: true,//开启数据持久化
    //   strategies: [
    //     {
    //       key: 'userStore',//给一个要保存的名称
    //       storage: sessionStorage,//sessionStorage / localStorage 存储方式
    //     }
    //   ]
    // },
    state: () => ({
      token: getToken(),
      userInfo: {},
      name: '',
      avatar: '',
      roles: [],
      permissions: [],
      sidebarMenus: [],
    }),
    actions: {
      // 登录
      login(userInfo) {
        return new Promise(async (resolve, reject) => {
          let res = await login(userInfo);
          if (res.code == 200) {
            this.token = res.data.token;
            this.name = res.data.user_name
            setToken(res.data.token);
            this.setUserInfo(res.data);
            resolve()
          } else {
            reject(res)
          }
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          getInfo().then(res => {
            const user = res.user
            const avatar = (user.avatar == "" || user.avatar == null) ? defAva : import.meta.env.VITE_APP_BASE_API + user.avatar;
            if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
              this.roles = res.roles
              this.permissions = res.permissions
            } else {
              this.roles = ['ROLE_DEFAULT']
            }
            this.name = user.userName
            this.avatar = avatar;
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 退出系统
      logOut() {
        return new Promise(async (resolve, reject) => {
          let res = await logout(this.token);
          if (res.code == 200) {
            this.token = ''
            this.roles = []
            this.permissions = []
            this.userInfo = ""
            removeToken();
            resolve()
          } else {
            reject(error)
          }
        })
      },
      tokenExpired() {
        return new Promise((resolve, reject) => {
          this.token = ''
          this.roles = []
          this.permissions = []
          this.userInfo = "";
          removeToken()
          resolve()
        })
      },
      setUserInfo(value) {
        this.userInfo = value;
        sessionStorage.setItem('userInfo', JSON.stringify(value))
      },
      setSidebarMenus(value) {
        this.sidebarMenus = value;
      }
    }
  })

export default useUserStore
