<template>
  <!-- :class="{ 'open-scend-menu': toLeftMenuOpen }" -->
  <div class="app-wrapper openSidebar" :style="{ '--current-color': theme }">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
    <sidebar v-if="!sidebar.hide" class="sidebar-container" />
    <!-- <menu-scend></menu-scend> -->
    <head-menu class="fixed-top-menu"></head-menu>
    <div :class="{ hasTagsView: needTagsView, sidebarHide: sidebar.hide }" class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <tags-view v-if="needTagsView" />
        <!-- <navbar @setLayout="setLayout" /> -->
      </div>
      <app-main />
      <settings ref="settingRef" />
    </div>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeUnmount } from 'vue'
// import { useWindowSize } from '@vueuse/core'
import Sidebar from './components/Sidebar/index.vue'
import { AppMain, Navbar, Settings, TagsView, HeadMenu } from './components'
// import defaultSettings from '@/settings'
// import MenuScend from './components/Sidebar/MenuScend'
import useAppStore from '@/store/modules/app'
import useSettingsStore from '@/store/modules/settings'
import usePermissionStore from '@/store/modules/permission'
import MqttExamp from "@/plugins/mqtt"

const { appContext } = getCurrentInstance();


const settingRef = ref(null);
const appStore = useAppStore();
const settingsStore = useSettingsStore()
const permissionStore = usePermissionStore()
const theme = computed(() => settingsStore.theme);
// const sideTheme = computed(() => settingsStore.sideTheme);
const sidebar = computed(() => appStore.sidebar);
const device = computed(() => appStore.device);
const needTagsView = computed(() => settingsStore.tagsView);
const fixedHeader = computed(() => settingsStore.fixedHeader);
// const toLeftMenuOpen = computed(() => appStore.toLeftMenuOpen && appStore.sidebar.opened);

// 挂载 mqtt 
let baseUrl = import.meta.env.VITE_APP_ENV == 'development' ? 'ws://' : 'wss://'
// const client = new MqttExamp({
//   url: baseUrl + permissionStore.mqttInfo.server + ':' + permissionStore.mqttInfo.port + '/mqtt',
//   options: {
//     clean: false, // 保留会话
//     clientId: permissionStore.mqttInfo.clientid,
//     username: permissionStore.mqttInfo.username,
//     password: permissionStore.mqttInfo.password, //密码 可以为空
//     connectTimeout: 4000, // 超时时间
//     // reconnectPeriod: 4000, // 重连时间间隔
//     // keepalive: 30,
//   }
// });
// appContext.config.globalProperties.$client = client;

// const classObj = computed(() => ({
//   hideSidebar: !sidebar.value.opened,
//   openSidebar: sidebar.value.opened,
//   withoutAnimation: sidebar.value.withoutAnimation,
//   mobile: device.value === 'mobile'
// }))

// const { width, height } = useWindowSize();
// const WIDTH = 992; // refer to Bootstrap's responsive design

watchEffect(() => {
  if (device.value === 'mobile' && sidebar.value.opened) {
    useAppStore().closeSideBar({ withoutAnimation: false })
  }
  // if (width.value - 1 < WIDTH) {
  //   useAppStore().toggleDevice('mobile')
  //   useAppStore().closeSideBar({ withoutAnimation: true })
  // } else {
  //   useAppStore().toggleDevice('desktop')
  // }
})

function handleClickOutside() {
  useAppStore().closeSideBar({ withoutAnimation: false })
}
// function setLayout() {
//   settingRef.value.openSetting();
// }

onBeforeUnmount(() => {
  // client.over()
})

</script>

<script>
export default {
  name: "Layout"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.module.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.open-scend-menu {
  .fixed-header {
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 9;
    width: calc(100% - $base-sidebar-width - $base-sceond-width);
    transition: width 0.28s;
  }
}


.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$base-sidebar-width});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.sidebarHide .fixed-header {
  width: 100%;
}

.mobile .fixed-header {
  width: 100%;
}

.fixed-top-menu {
  position: fixed;
  top: 0;
  left: 0;
  // left: 90px;
  z-index: 999;
  width: 100vw;
  box-shadow: 0 1px 4px rgba(0, 1, 2, 0.08);
}
</style>