import request from '@/utils/request'

// 获取路由
export function getRouters() {
    return request({
        url: '/v1/menu/list',
        method: 'post'
    })
}

// 导出-创建
export function export_create(data) {
    return request({
        url: '/v1/export/create',
        headers: {
            isToken: true,
        },
        method: 'post',
        data: data
    })
}

// 导出-清空
export function export_clear(data) {
    return request({
        url: '/v1/export/clear',
        headers: {
            isToken: true,
        },
        method: 'post',
        data: data
    })
}

// 导出-列表
export function export_list(data) {
    return request({
        url: '/v1/export/list',
        headers: {
            isToken: true,
        },
        method: 'post',
        data: data
    })
}

// 导出-下载
export function export_down(data) {
    return request({
        url: '/v1/export/down',
        headers: {
            isToken: true,
        },
        method: 'post',
        data: data,
        responseType: 'arraybuffer'
    })
}

// 帮助
export function mneu_help(data) {
    return request({
        url: '/v1/menu/help',
        headers: {
            isToken: true,
        },
        method: 'post',
        data: data
    })
}

// 单图片上传
export function one_image(data) {
    return request({
        url: "/v1/upload/one-image",
        headers: {
            isToken: true,
            "Content-Type": "multipart/form-data",
        },
        method: "post",
        data: data
    })
}