import XEUtils from 'xe-utils'
import { VXETable, Table, Edit, Validator, Keyboard } from 'vxe-table'
import zhCN from 'vxe-table/es/locale/lang/zh-CN'

// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

export default function useTable(app) {
    app.use(Table)
    app.use(Edit)
    app.use(Validator)
    app.use(Keyboard)
}