import auth from '@/plugins/auth'
import Layout from '@/layout/index'
import outSide from "@/layout/outSide"
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'
import router, { constantRoutes, dynamicRoutes } from '@/router'
import { getRouters } from '@/api/common'
import { sys_get_mqtt } from "@/api/system/user"

// import { headerMenu } from "@/router/dynamicRoutes"

// 匹配views里面所有的.vue文件
const modules = import.meta.glob('./../../views/**/*.vue');

const usePermissionStore = defineStore(
  'permission',
  {
    state: () => ({
      routes: [],
      addRoutes: [],
      defaultRoutes: [],
      topbarRouters: [],
      sidebarRouters: [],
      choseChildrenRouter: null,   //左侧路由下的当前选中的子路由
      activeTopMenu: "", // 顶部默认选中按钮
      buttonPermission: {}, //按钮权限
      mqttInfo: {}
    }),
    actions: {
      setRoutes(routes) {
        this.addRoutes = routes
        this.routes = constantRoutes.concat(routes);
      },
      setMqttInfo(mqttInfo) {
        this.mqttInfo = mqttInfo;
      },
      setDefaultRoutes(routes) {
        this.defaultRoutes = constantRoutes.concat(routes)
      },
      setTopbarRoutes(routes) {
        this.topbarRouters = routes
      },
      setSidebarRouters(routes) {
        this.sidebarRouters = routes;
      },
      setButtonPermission(value) {
        this.buttonPermission = value;
      },
      generateRoutes() {
        return new Promise(resolve => {
          // 向后端请求路由数据
          getRouters().then(res => {
            let getRoute = changeWriteRouter(res.data.sub_menu);
            //保存按钮权限
            this.setButtonPermission({
              is_sys_create: res.data.is_sys_create,
              action: res.data.action
            })
            // this.setActiveTopMenu(res.data.sub_menu[0].id);
            console.log(getRoute);
            const sdata = JSON.parse(JSON.stringify(getRoute));
            const rdata = JSON.parse(JSON.stringify(getRoute));
            const defaultData = JSON.parse(JSON.stringify(getRoute));
            const sidebarRoutes = filterAsyncRouter(sdata);
            const rewriteRoutes = filterAsyncRouter(rdata, false, true);
            const defaultRoutes = filterAsyncRouter(defaultData);
            const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
            asyncRoutes.forEach(route => { router.addRoute(route) });
            this.setRoutes(rewriteRoutes);
            // console.log(constantRoutes.concat(sidebarRoutes));
            this.setSidebarRouters(constantRoutes.concat(sidebarRoutes));
            this.setDefaultRoutes(sidebarRoutes);
            this.setTopbarRoutes(defaultRoutes);
            // this.setHeaderRouters(res.data.top_menu);   // 设置顶部路由菜单
            resolve(rewriteRoutes);
          })
        })
      },
      setChoseChildrenRouter(routes) {
        this.choseChildrenRouter = routes;
      }
    }
  })

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink
      } else if (route.component === 'outSide') {
        route.component = outSide;
      } else {
        route.component = loadView(route.component, route)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = []
  routes.forEach(route => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route)
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route)
      }
    }
  })
  return res
}

export const loadView = (view, route) => {
  let res;
  for (const path in modules) {
    const dir = path.split('views/')[1].split('.vue')[0];
    if (dir === view) {
      res = async () => {
        let data = await modules[path]();
        return {
          ...data.default,
          name: route.name
        };
      }
    }
  }
  return res;
}

function changeWriteRouter(router) {
  let newRouter = [];
  router.map(item => {
    return deepOnceRouter(item)
  }).forEach(item => {
    newRouter.push(item);
  })
  return newRouter;
}

function deepOnceRouter(router) {
  return {
    path: router.url || '/' + router.id,
    name: router.id + "",
    props: { api: router.api, data: router.params == "" ? {} : router.params },
    hidden: !router.show,
    meta: {
      title: router.name,
      icon: router.icon,
      pid: router.pid,
      id: router.id,
      show: router.show,
      sort: router.sort,
      position: router.position ? router.position : null,
      easy_close: router.file == 'compage/add' ? false : true,
    },
    redirect: "noRedirect",
    component: router.file,
    children: router.children ? deepMoreRouter(router.children) : []
  }
}

function deepMoreRouter(routerArr) {
  return routerArr.map(item => {
    return deepOnceRouter(item)
  })
}

export default usePermissionStore
