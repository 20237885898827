export default {
    "routes": {

    },
    "validata": {
        "required_phone": "Please enter your account phone number",
        "error_phone": "Please enter the correct phone number",
        "required_code": "Please enter your merchant code",
        "required_pwd": "please enter your PIN",
        "notnull_oldpwd": "The old password cannot be empty",
        "notnull_newpwd": "The new password cannot be empty",
        "notnull_confpwd": "Confirm that the password cannot be empty",
        "length_limit6_20": "6 to 20 characters in length",
        "not_same": "The two passwords are different"
    },
    "btn": {
        "cancel": "cancel",
        "comfirm": "comfirm",
        "save": "save",
        "close": "close"
    },
    "srcUserProfile": {
        "langChosePlaceholder": "Place Chose your language",
        "edit_pwd": "change password",
        "oldPassword": "Old password",
        "input_oldPwd": "Please enter your old password",
        "newPassword": "New password",
        "input_newPwd": "Please enter your new password",
        "confirm_pwd": "confirm password",
        "input_confirm_pwd": "Please enter the confirmation password"
    },
    "srcLogin": {
        "title": "Backstage Management System",
        "username": "Phone/UserName",
        "password": "Password",
        "code": "Merchant code",
        "mobile": "cell-phone number",
        "rememberPwd": "Remember the password",
        "login": "Log in",
        "logining": "Logging. . ."
    }

}