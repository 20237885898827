import Cookies from 'js-cookie'

const useAppStore = defineStore(
  'app',
  {
    state: () => ({
      sidebar: {
        opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
        withoutAnimation: false,
        hide: false,
        showMenuDrawer: false,
      },
      device: 'desktop',
      toLeftMenuOpen: false, // 通过左边路由打开二级
      first_time_entered: true, // 是否第一次进入
      size: Cookies.get('size') || 'default',
      language: Cookies.get('language') || 'zh-CN',
    }),
    actions: {
      toggleSideBar(withoutAnimation) {
        if (this.sidebar.hide) {
          return false;
        }
        if (this.toLeftMenuOpen) {
          this.sidebar.opened = !this.sidebar.opened;
        }
        this.sidebar.withoutAnimation = withoutAnimation;
        console.log(this.sidebar.opened, this.toLeftMenuOpen, "tigger");
        // if (this.sidebar.opened) {
        //   Cookies.set('sidebarStatus', 1)
        // } else {
        //   Cookies.set('sidebarStatus', 0)
        // }
      },
      closeSideBar(withoutAnimation) {
        Cookies.set('sidebarStatus', 0)
        this.sidebar.opened = false
        this.sidebar.withoutAnimation = withoutAnimation
      },
      toggleDevice(device) {
        this.device = device
      },
      setSize(size) {
        this.size = size;
        Cookies.set('size', size)
      },
      toggleSideBarHide(status) {
        this.sidebar.hide = status
      },
      setLang(value) {
        this.language = value;
        Cookies.set('language', value);
      },
      setMenuDrawer(value) {
        this.sidebar.showMenuDrawer = value;
      },
      settoLeftMenuOpen(value) {
        this.toLeftMenuOpen = value;
        this.sidebar.opened = value;
      },
      setHiddenMenu() {
        this.toLeftMenuOpen = true;
        this.sidebar.opened = false;
      },
      setFirstTimeEntered() {
        this.first_time_entered = false;
      }
    }
  })

export default useAppStore
