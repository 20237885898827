import router from './router'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { isHttp } from '@/utils/validate'
import { isRelogin } from '@/utils/request'
import useUserStore from '@/store/modules/user'
import useSettingsStore from '@/store/modules/settings'
import usePermissionStore from '@/store/modules/permission'

NProgress.configure({ showSpinner: false });

const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/forgetPwd', '/letter', '/information'];

router.beforeEach((to, from, next) => {

  handleKeepAlive(to)
  NProgress.start()
  if (getToken()) {

    record(to)
    to.meta.title && useSettingsStore().setTitle(to.meta.title)
    console.log(to);
    /* has token*/
    if (to.path === '/login') {
      next()
      NProgress.done()
    } else {
      if (usePermissionStore().sidebarRouters.length == 0) {
        usePermissionStore().generateRoutes().then(accessRoutes => {
          // 根据roles权限生成可访问的路由表
          accessRoutes.forEach(route => {
            // if (!isHttp(route.path)) {
            router.addRoute(route) // 动态添加可访问路由表
            // }
          })
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        }).catch(err => {
          // console.log(err.message);
          useUserStore().logOut().then(() => {
            ElMessage.error(err)
            next({ path: '/login' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
    }
  }
  //   } else {
  //     next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
  //     NProgress.done()
  //   }
  // }
})

router.afterEach(() => {
  NProgress.done()
})



// function handleKeepAlive(to) {
//   if (to.matched && to.matched.length > 2) {
//     for (let i = 0; i < to.matched.length; i++) {
//       const element = to.matched[i]
//       if (element.components.default.name === 'outSide') {
//         to.matched.splice(i, 1)
//         handleKeepAlive(to)
//       }
//     }
//   }
// }

/**
 * 方法二：兼容<layout>按需加载
 * @param to
 */
async function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      const element = to.matched[i]
      // console.log(element.components);
      if (element.components.default.name === "outSide") {
        to.matched.splice(i, 1)
        await handleKeepAlive(to)
      }
      // 如果没有按需加载完成则等待加载
      if (typeof element.components.default === 'function') {
        await element.components.default()
        await handleKeepAlive(to)
      }
    }
  }
}

// 保存历史记录
function record(to) {
  if (to.meta.show) {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    let pathLists = JSON.parse(localStorage.getItem('record_' + userInfo.user_id)) || []
    if (pathLists.length > 0) {
      if (pathLists.length >= 15) {
        pathLists.pop()
      }
      pathLists.forEach((item, index) => {
        if (item.path === to.path) {
          pathLists.splice(index, 1)
        }
      })
      pathLists.unshift({ path: to.path, title: to.meta.title })
      localStorage.setItem('record_' + userInfo.user_id, JSON.stringify(pathLists))
    } else {
      pathLists.unshift({ path: to.path, title: to.meta.title })
      localStorage.setItem('record_' + userInfo.user_id, JSON.stringify(pathLists))
    }
  }
}