import mZhLocale from './lang/zh'
import mEnLocale from './lang/en'
import { createI18n } from 'vue-i18n';
import Cookies from 'js-cookie'

const messages = {
    'en-US': { ...mEnLocale },
    'zh-CN': { ...mZhLocale }
}
let locale = Cookies.get('language') || 'zh-CN';
Cookies.set(locale);

let index = Object.keys(messages).findIndex(item => item == locale)

if (index != -1) {
    locale = Object.keys(messages)[index]
}

const i18n = createI18n({
    // 使用 Composition API 模式，则需要将其设置为false
    legacy: false,
    // 全局注入 $t 函数
    globalInjection: false,
    locale,
    messages,
    // 防止使用v-html=“$t(“”)”的xss警告
    warnHtmlMessage: false
})

export default i18n;