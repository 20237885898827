<template>
  <router-view />
</template>

<script setup>
import useSettingsStore from '@/store/modules/settings'
import { handleThemeStyle } from '@/utils/theme'
import { getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n'
const { appContext } = getCurrentInstance()
const i18n = useI18n();

console.log("当前打包时间  20240201   1058")

// 改写i18n方法重新挂载到全局
// start
let newI18n = i18n.t;
i18n.t = (path, chinesName) => {
  return newI18n(path)
}

// const clientq = mqtt.connect("ws://mqt.wonder.pub:8083/mqtt", {
//   clean: false, // 保留会话
//   clientId: "mqttx_lbcdbc",
//   username: "mmy06",
//   password: "mmy06", //密码 可以为空
//   connectTimeout: 4000, // 超时时间
//   // reconnectPeriod: 4000, // 重连时间间隔
//   // keepalive: 30,
// })

// clientq.on('connect', () => {
//   console.log("链接成功");
//   clientq.subscribe('151', error => {
//     if (!error) {
//       console.log("订阅成功");
//     } else {
//       console.log("订阅失败");
//     }
//   })
// })
// clientq.on('reconnect', error => {
//   console.log(error, "reconnect");
// })
// clientq.on('message', (topic, message) => {
//   console.log(topic, message);
// })

appContext.config.globalProperties.$t = i18n.t;


// end

onMounted(() => {
  nextTick(() => {
    // 初始化主题样式
    handleThemeStyle(useSettingsStore().theme)
  })
})


</script>
