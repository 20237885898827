import axios from 'axios'
import { ElNotification, ElMessageBox, ElMessage, ElLoading } from 'element-plus'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from '@/utils/ruoyi'
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver'
import useUserStore from '@/store/modules/user'
import useAppStore from '@/store/modules/app'
import Qs from 'qs';

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// const baesUrl = import.meta.env.VITE_APP_BASE_API;
// const baesUrl = import.meta.env.VITE_APP_BASE_HTTP;
// console.log(baesUrl)
const loaclUrl = sessionStorage.getItem("api");
let base_api = ""
switch (loaclUrl) {
  case "http://lbc.test.com/":
    base_api = "/lbc-api"
    break;
  case "http://www.mmy.com/":
    base_api = "/yww-api"
    break;
  case "http://yii2yunban.com/":
    base_api = "/lhl-api"
    break;
  case "http://dev.yd.miaomaiyun.com/api/":
    base_api = "/xs-api"
    break;
  case "http://zyme.api.yd.com/":
    base_api = "/lzy-api"
    break;
  case "http://yyy.api.com/":
    base_api = "/yyy-api"
    break;
  default:
    base_api = '/dev-api';
    break;
}

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: import.meta.env.VITE_APP_BASE_API,
  // 超时
  timeout: 30000
})



// request拦截器
service.interceptors.request.use(config => {
  // let url_arr = window.location.href.split('/');
  // url_arr = url_arr[0] + '//' + url_arr[2]
  // config.baseURL = import.meta.env.VITE_APP_ENV == 'development' && base_api != '/dev-api' ? base_api : JSON.parse(localStorage.getItem("traderObj")).erp_api_base_url
  // config.baseURL = import.meta.env.VITE_APP_BASE_HTTP
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken || config.method === 'post') {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['lang'] = useAppStore().language.substring(0, 2);
  config.headers['source'] = "pc";

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    if (config.headers['Content-Type'] != 'multipart/form-data') {
      config.data = Qs.stringify(config.data);
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                // 请求地址
      const s_data = sessionObj.data;              // 请求数据
      const s_time = sessionObj.time;              // 请求时间
      const interval = 500;                       // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        // console.warn(`[${s_url}]: ` + message)
        // return Promise.reject(new Error(message))
        return
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 401 || code === 302) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        isRelogin.show = false;
        useUserStore().tokenExpired().then(() => {
          sessionStorage.clear();
          location.href = window.location.pathname;
        })
      }).catch(() => {
        isRelogin.show = false;
      });
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code !== 200) {
    return Promise.resolve(res.data)
  } else {
    return Promise.resolve(res.data)
  }
}, error => {
  // console.log('err' + error)
  let { message } = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
    return Promise.resolve({ message: message, code: "error" })
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时";
    return Promise.resolve({ message: message, code: "error" })
  } else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
    return Promise.resolve({ message: message, code: "error" })
  }
  // ElMessage({ message: message, type: 'error', duration: 5 * 1000 })
})

// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = ElLoading.service({ text: "正在下载数据，请稍候", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      ElMessage.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    ElMessage.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export function commonRequest({ api, method, data, headers }) {
  return service({
    url: api,
    method: method || "post",
    headers: headers,
    data: data,
  })
}


export default service
