export default {
    "routes": {

    },
    "validata": {
        "required_phone": "请输入您的账号/手机号",
        "required_code": "请输入您的商户编码",
        "error_phone": "请输入正确手机号",
        "required_pwd": "请输入您的密码",
        "notnull_oldpwd": "旧密码不能为空",
        "notnull_newpwd": "新密码不能为空",
        "notnull_confpwd": "确认密码不能为空",
        "length_limit6_20": "长度在 6 到 20 个字符",
        "not_same": "两次输入的密码不一致"
    },
    "btn": {
        "cancel": "取消",
        "comfirm": "确认",
        "save": "保存",
        "close": "关闭"
    },
    "srcUserProfile": {
        "langChosePlaceholder": "请选择您的语言",
        "edit_pwd": "修改密码",
        "oldPassword": "旧密码",
        "input_oldPwd": "请输入旧密码",
        "newPassword": "新密码",
        "input_newPwd": "请输入新密码",
        "confirm_pwd": "确认密码",
        "input_confirm_pwd": "请输入确认密码",
    },
    "srcLogin": {
        "title": "银枣·伙伴平台",
        "username": "账号",
        "password": "密码",
        "code": "商户编码",
        "mobile": "手机号",
        "rememberPwd": "记住密码",
        "login": "登 录",
        "logining": "登 录 中. . ."
    }

}