<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :include="tagsViewStore.cachedViews">
          <component :is="Component" :key="route.path" />
        </keep-alive>
      </transition>
    </router-view>
    <!-- <iframe-toggle /> -->
  </section>
</template>

<script setup>
import { watch } from "vue";
import iframeToggle from "./IframeToggle/index"
import useTagsViewStore from '@/store/modules/tagsView'

const tagsViewStore = useTagsViewStore();

watch(() => tagsViewStore.cachedViews, (newVal) => {
  // console.log(newVal, "tagsViewStore");
}, { immediate: true, deep: true })

</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.module.scss";

.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  // min-width: 1100px;
  position: relative;
  overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    min-height: calc(100vh);
    background: rgb(250, 250, 250);
    min-width: calc(1100px - $base-sidebar-width);
  }

  .fixed-header+.app-main {
    padding-top: $base-fixed-heigth;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    // padding-right: 17px;
  }
}
</style>