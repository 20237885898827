<template>
  <div>
    <svg-icon icon-class="question" @click="getQuestion" />

    <el-drawer v-model="helpDrawer" direction="rtl" :z-index="999" append-to-body modal-class="li-drawer">
      <template #header>
        <h4>帮助</h4>
      </template>
      <template #default>
        <el-scrollbar style="height: calc(100vh - 82px);">
          <div v-html="helpData"></div>
        </el-scrollbar>
      </template>
      <!-- <template #footer>
        <div style="flex: auto">
          <el-button @click="cancelClick">cancel</el-button>
          <el-button type="primary" @click="confirmClick">confirm</el-button>
        </div>
      </template> -->
    </el-drawer>
  </div>
</template>

<script setup>
import { mneu_help } from "@/api/common/index"
import { ElMessage } from "element-plus";
import { computed, ref } from "vue"
import { useRoute } from "vue-router";

const helpDrawer = ref(false);

const route = useRoute();
const helpData = ref();
const cache_id = ref();

async function getQuestion() {
  //ToDo   获取当前路由的帮助信息
  if (route.meta.id == cache_id.value) {
    helpDrawer.value = true;
    return;
  }
  let res = await mneu_help({ mdl_id: route.meta.id })
  if (res.code == 200) {
    cache_id.value = route.meta.id;
    helpData.value = res.data.content;
    helpDrawer.value = true;
  } else {
    ElMessage.error(res.message);
  }
}


</script>

<style lang="scss">
.li-drawer {

  .el-drawer__header {
    padding: 0;
    margin-bottom: 0px;
    background: #F8F8F8;

    h4 {
      margin: 0;
      margin-left: 18px;
      line-height: 42px;
    }
  }
}
</style>