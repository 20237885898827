<template>
  <div v-if="!item.hidden">

    <!-- <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path, onlyOneChild.query)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)"
          :class="[{ 'submenu-title-noDropdown': !isNest }, { 'childrenActive': is_choseChildren == item.path }]"
          @click="(event) => closeDraw(event, item)">
          <template #title>
            <span class="mmy-flexc-ac menuBox">
              <svg-icon class="menuIcon" :icon-class="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" />
              <span class="menu-title" :title="hasTitle(onlyOneChild.meta.title)">
                {{ onlyOneChild.meta.title }}
              </span>
            </span>
          </template>
        </el-menu-item>
      </app-link>
    </template> -->

    <template v-if="item.meta && item.children && item.children.length >= 1 && !item.hidden">
      <el-menu-item :index="resolvePath(item.path)"
        :class="[{ 'submenu-title-noDropdown': !isNest }, { 'childrenActive': is_choseChildren == item.path }]"
        @mouseover="openDraw(item)" @mouseout="outDraw">
        <template #title>
          <span class="mmy-flexc-ac menuBox">
            <svg-icon class="menuIcon" :icon-class="item.meta.icon || (item.meta && item.meta.icon)" />
            <span class="menu-title" :title="hasTitle(item.meta.title)">{{ item.meta.title }}</span>
          </span>
        </template>
      </el-menu-item>
    </template>

    <!--     <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template v-if="item.meta" #title>
        <svg-icon :icon-class="item.meta && item.meta.icon" />
        <span class="menu-title" :title="hasTitle(item.meta.title)">{{ item.meta.title }}</span>
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
        :base-path="resolvePath(child.path)" class="nest-menu" />
    </el-sub-menu> -->
  </div>
</template>

<script setup>
import { isExternal } from '@/utils/validate'
import AppLink from './Link'
import { getNormalPath } from '@/utils/ruoyi'
import useAppStore from '@/store/modules/app';
import usePermissionStore from '@/store/modules/permission'
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  // route object
  item: {
    type: Object,
    required: true
  },
  isNest: {
    type: Boolean,
    default: false
  },
  basePath: {
    type: String,
    default: ''
  }
})
const appStore = useAppStore();
const permission = usePermissionStore();
const route = useRoute();

const onlyOneChild = ref({});
const is_choseChildren = computed(() => route.matched[0].path);

const timer = ref();

function hasOneShowingChild(children = [], parent) {
  if (!children) {
    children = [];
  }
  const showingChildren = children.filter(item => {
    if (item.hidden) {
      return false
    } else {
      // Temp set(will be used if only has one showing child)
      onlyOneChild.value = item
      return true
    }
  })

  // When there is only one child router, the child router is displayed by default
  if (showingChildren.length === 1) {
    return true
  }

  // Show parent if there are no child router to display
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: '', noShowingChildren: true }
    return true
  }
  return false
};

function resolvePath(routePath, routeQuery) {
  if (isExternal(routePath)) {
    return routePath
  }
  if (isExternal(props.basePath)) {
    return props.basePath
  }
  if (routeQuery) {
    let query = JSON.parse(routeQuery);
    return { path: getNormalPath(props.basePath + '/' + routePath), query: query }
  }
  return getNormalPath(props.basePath + '/' + routePath)
}

function hasTitle(title) {
  if (title.length > 5) {
    return title;
  } else {
    return "";
  }
}

function closeDraw(event, item) {
  appStore.setMenuDrawer(false);
  // appStore.settoLeftMenuOpen(false);
}

function openDraw(item) {
  if (timer.value) clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    //打开多级弹窗
    appStore.setFirstTimeEntered();
    appStore.setMenuDrawer(true);
    // appStore.settoLeftMenuOpen(true);
    // 保存当前选中路由组
    permission.setChoseChildrenRouter(item);
  }, 300);
}

function outDraw() {
  clearTimeout(timer.value);
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.module.scss";

.el-menu-item:hover {
  background-color: transparent !important;
}
.childrenActive {
  .menuBox {
    background-color: $--color-success1;
    color: $--color-success;
  }
}

.el-menu-item {
  height: calc(var(--el-menu-item-height, 56px)) !important;
  justify-content: center;
  padding: 0 !important;
}

.menu-title {
  line-height: normal;
}

.menuBox {
  width: 62px;
  height: 52px;
  padding: 10px 5px;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  &:hover {
    background: rgb(0, 210, 110,.1);
    color: $--color-success;
  }

  .menuIcon {
    font-size: 16px;
    margin-bottom: 0 !important;
  }
}

.el-menu-item.is-active {
  color: var(--el-menu-text-color) !important;
  background: none !important;

  // .rock-menu {
  //   background: $--color-primary;
  // }
}
</style>