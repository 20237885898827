<template>
    <div :class="classObj" v-if="showMenuDrawer">
        <el-drawer :model-value="showMenuDrawer" ref="drawerMenu" :size="600" direction="ltr" modal-class="drawer-menu"
            @close="closeDrawer">
            <template v-if="childMeun" #header="{ titleId, titleClass }">
                <div :id="titleId" :class="titleClass">{{ childMeun.title }}</div>
            </template>
            <!-- 此处不对多级路由校验。路由最多为3级 -->
            <el-scrollbar class="scroll-box">
                <div class="menu-box">
                    <template v-for="(item, index) in childMeun.menuList" :key="index">
                        <div class="item-box" :class="hasChildren(item) ? 'block' : 'no-block'"
                        v-if="hasChildren(item) && !item.hidden">
                            <div class="hasChilMenu">
                                <div class="title">{{ item.meta.title }}</div>
                                <div class="child-menu-box">
                                    <template v-for="(_item, _index) in  item.children" :key="_index">
                                        <div v-if="!_item.hidden"
                                            :class="['child-item', { 'active': activeMenu == routerPath(childMeun, item, _item) }]"
                                            @click="JumpTo(childMeun, item, _item)">
                                            <span> {{ _item.meta.title }}</span>
                                            <svg-icon v-if="!_item.meta.is_collect" icon-class="star-white"
                                                class="star-white" title="收藏"
                                                @click.stop="handleCollect(_item, true)"></svg-icon>
                                            <svg-icon v-if="_item.meta.is_collect" icon-class="star" class="star-black"
                                                title="收藏" @click.stop="handleCollect(_item, false)"></svg-icon>
                                        </div>
                                    </template>
                                </div>
                            </div>
                    </div>
                    </template>
                    <!-- <div class="item-box" :class="hasChildren(item) ? 'block' : 'no-block'"
                        v-for="(item, index) in childMeun.menuList" :key="index">
                        <div v-if="hasChildren(item) && !item.hidden" class="hasChilMenu">
                            <div class="title">{{ item.meta.title }}</div>
                            <div class="child-menu-box">
                                <template v-for="(_item, _index) in   item.children  " :key="_index">
                                    <div v-if="!_item.hidden"
                                        :class="['child-item', { 'active': activeMenu == routerPath(childMeun, item, _item) }]"
                                        @click="JumpTo(childMeun, item, _item)">
                                        <span> {{ _item.meta.title }}</span>
                                        <svg-icon v-if="!_item.meta.is_collect" icon-class="star-white" class="star-white"
                                            title="收藏" @click.stop="handleCollect(_item, true)"></svg-icon>
                                        <svg-icon v-if="_item.meta.is_collect" icon-class="star" class="star-black"
                                            title="收藏" @click.stop="handleCollect(_item, false)"></svg-icon>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="group-item"></div> -->
                </div>
            </el-scrollbar>
        </el-drawer>
    </div>
</template>

<script setup>
import { computed, nextTick, onMounted } from 'vue';
import useAppStore from '@/store/modules/app';
import usePermissionStore from '@/store/modules/permission';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    itemInfo: {
        type: Object,
        default: {}
    },
})
const appStore = useAppStore();
const permission = usePermissionStore();
const router = useRouter();
const route = useRoute();

const drawerMenu = ref(null);

const showMenuDrawer = computed(() => appStore.sidebar.showMenuDrawer);

const childMeun = computed(() => {
    if (permission.choseChildrenRouter) {
        // console.log(permission.choseChildrenRouter, 787878);

        let firstLine = [];
        let secondLine = [];
        let threeLine = [];

        permission.choseChildrenRouter.children.forEach(item => {
            if (item.meta.position == 1) {
                firstLine.push(item);
            } else if (item.meta.position == 2) {
                secondLine.push(item);
            } else if (item.meta.position == 3) {
                threeLine.push(item);
            }
        });

        return {
            path: permission.choseChildrenRouter.path,
            title: permission.choseChildrenRouter.meta.title,
            menuList: permission.choseChildrenRouter.children,
            menuGroup: {
                firstLine,
                secondLine,
                threeLine
            }
        }
    }
    return null
});

const sidebar = computed(() => appStore.sidebar);
const device = computed(() => appStore.device);
const classObj = computed(() => ({
    'open-draw': sidebar.value.opened,
    'mobile-drow': device.value == 'mobile',
    'desktop-drow': device.value == 'desktop'
}))
const activeMenu = computed(() => route.path);


nextTick(() => {
    // console.log(drawerMenu.value.$el.target);
})

function closeDrawer(event) {
    appStore.setMenuDrawer(event)
}

function hasChildren(menu) {
    if (!menu.children) {
        return false;
    }

    if (menu.children.length > 0) {
        return true;
    } else {
        return false;
    }
}

function routerPath(...arg) {
    let str = "";
    for (let key of arg) {
        str += "/" + key.path
    }
    return str.substring(1)
}

// 跳转路由
function JumpTo(...arg) {
    router.push({
        path: routerPath(...arg)
    })
    appStore.setMenuDrawer(false);
}

//收藏
function handleCollect(item, status) {
    if (status) {
        item.meta.is_collect = true;
    } else {
        item.meta.is_collect = false;
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.module.scss";

:deep(.drawer-menu) {
    height: calc(100vh - 50px) !important;
    // border-left: 1px solid #EEEEEE;
    // &>div:first-child {
    //     width: auto !important;
    // }
}

.open-draw {
    &>:deep(.el-overlay) {
        z-index: 9999 !important;
        left: $base-sidebar-width !important;
        top: 50px;
    }
}

.desktop-drow {
    &>:deep(.el-overlay) {
        z-index: 9999 !important;
        left: $base-sidebar-width;
        top: 50px;
    }
}

.mobile-drow {
    &>:deep(.el-overlay) {
        z-index: 9999 !important;
        left: 0px;
        top: 50px;
    }
}

:deep(.drawer-menu) {
    .el-drawer__header {
        font-weight: 700;
        font-size: 18px;
        color: #000;
        margin-bottom: 0px;
        padding: 10px var(--el-drawer-padding-primary);
        // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
        //     0 0 3px 0 rgba(0, 0, 0, 0.04);
    }

    .el-drawer__body {
        padding: 5px 16px;
        width: auto !important;
        overflow-y: hidden;
    }
}

.scroll-box {
    height: calc(100% - 42px);
}

.menu-box {
    column-count: auto;
    -webkit-column-count: auto;
    // -o-column-count: 3;
    // -ms-column-count: 3;
    // -moz-column-count: 3;
    // column-fill:auto;
    column-gap: 1px;
    -webkit-column-gap: 1px;
    -o-column-gap: 1px;
    -ms-column-gap: 1px;
    -moz-column-gap: 1px;
    column-width:200px;

    .item-box {
        cursor: pointer;
        font-size: 14px;
        box-sizing: border-box;
        display: inline-block;
        .hasChilMenu {
            margin-bottom: 20px;
            display: inline-block;

            .title {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .child-menu-box {
                display: inline-block;

                .child-item {
                    font-size: 12px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 2px;

                    &:hover {
                        background: rgba(0, 0, 0, .08);
                        color: $--color-success;

                        .star-white {
                            display: block;
                        }
                    }

                    .star-white,
                    .star-black {
                        display: none;
                        margin: 0 !important;
                    }

                    .star-black {
                        display: block;
                        color: rgb(255, 201, 0);
                    }

                }
            }
        }
    }

    .block {
        display: block;
    }

    .no-block {
        display: inline-block;
        width: 50%;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;

        &>:hover {
            color: $--color-success;
        }
    }

    .active {
        color: $--color-success;
    }
}
</style>