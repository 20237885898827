import request from '@/utils/request'

// 普通登录/快捷登录 方法
export function login(data) {
  let url;
  if (data.way == 'common') {
    url = '/v1/user/login';
  } else {
    url = '/v1/user/login-shortcut';
  }
  delete data.way;
  return request({
    url: url,
    headers: {
      isToken: false,
    },
    method: 'post',
    data: data
  })
}

// 获取验证码
export function send_sms(data) {
  return request({
    url: "/v1/system/send-sms",
    headers: { isToken: false },
    method: 'post',
    data: data
  })
}

// 忘记密码
export function forget_pwd(data) {
  return request({
    url: "/v1/user/forget-pwd",
    headers: { isToken: false },
    method: 'post',
    data: data
  })
}

//用户修改密码
export function editPassWord(data) {
  return request({
    url: "/v1/user/edit-pwd",
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/v1/user/register',
    headers: { isToken: false },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/v1/user/logout',
    headers: {
      isToken: true
    },
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 根据手机号获得商户信息
export function get_trader(data) {
  return request({
    url: "/v1/user/get-trader",
    headers: { isToken: false },
    method: 'post',
    data: data
  })
}
// 用户第一次登录完善商户信息
export function saved_info(data) {
  return request({
    url: "/v1/trader/saved-info",
    headers: { isToken: true },
    method: 'post',
    data: data
  })
}
// 注册页面获取域名
export function server_list(data) {
  return request({
    url: "/v1/user/server-list",
    headers: { isToken: true },
    method: 'post',
    data: data
  })
}