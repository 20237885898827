<template>
    <div class="head-menu">
        <img class="logoImg" src="@/assets/images/hbpt_logo.svg" alt="" @click="toHome">
        <el-select v-show="isDevlopment" v-model="golbalValue" @change="globalApiChange">
            <el-option label="袁大哥" value="http://www.mmy.com/"></el-option>
            <el-option label="吴大哥" value="http://yii2yunban.com/"></el-option>
            <el-option label="杨龙杰" value="http://yyy.api.com/"></el-option>
            <el-option label="李总" value="http://zyme.api.yd.com/"></el-option>
            <el-option label="线上" value="http://dev.yd.miaomaiyun.com/api/"></el-option>
            <el-option label="mine" value="http://lbc.test.com/"></el-option>
        </el-select>

        <div class="right-menu">
            <ruo-yi-qut id="ruoyi-doc" class="right-menu-item hover-effect question-help" />

            <!-- <div class="input-search">
                <el-input v-model="gloablSearch" class="global-search" placeholder="请输入菜单" @focus="inputFocus"
                    @blur="inputBlur" @input="inputSearch">
                    <template #append>
                        <svg-icon :style="{ opacity: showClear ? 1 : 0 }" icon-class="circleClose" class="circleClose"
                            @click="clearGloablSearch"></svg-icon>
                    </template>
                </el-input>
                <div class="search-box" v-show="showSearchBox">
                    <div v-show="result.resultList.length != 0">
                        <div v-for="(item, index) in result.resultList" class="item" :key="index" @click="jumpToMenu(item)">
                            {{ item.title }}
                        </div>
                    </div>
                    <div class="item" v-show="result.resultList.length == 0 && gloablSearch.length > 0">暂无匹配结果</div>
                    <div v-show="showHistory && gloablSearch.length == 0">
                        <div class="title">搜索历史</div>
                        <div v-for="(item, index) in result.history" class="item" :key="index" @click="jumpToMenu(item)">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <template v-if="appStore.device !== 'mobile'">
                <language-chose color="#fff" class="right-menu-item"></language-chose>

                <el-tooltip :content="!isFullscreen ? '全屏' : '缩小'" effect="dark" placement="bottom">
                    <screenfull id="screenfull" class="right-menu-item hover-effect" />
                </el-tooltip>

                <el-tooltip content="布局大小" effect="dark" placement="bottom">
                    <size-select id="size-select" class="right-menu-item hover-effect" />
                </el-tooltip>
            </template> -->
            <div class="avatar-container">
                <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
                    <div class="avatar-wrapper mmy-flex-ac">
                        <img :src="userInfo.user_pic" class="user-avatar" />
                        <div class="mmy-fs-14 mmy-flex-ac">
                            <span >{{ userInfo && userInfo.user_cname }}</span>
                            <!-- <span style="color: #00FF00;">@{{ userInfo.traderMain && userInfo.traderMain.trader_simple }}</span> -->
                        </div>
                        <el-icon><caret-bottom /></el-icon>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <div class="dropdown-box" style="justify-content: flex-start;">
                                    <img :src="userInfo.user_pic" class="user-avatar" />
                                    <div class="dropdown-box-info">
                                        <span>{{ userInfo.user_cname }}</span>
                                        <span>{{ userInfo.userMobile &&
                                            userInfo.userMobile.user_mobile }}</span>
                                    </div>
                                </div>
                            </el-dropdown-item>
                            <!-- <el-dropdown-item>
                                <div class="dropdown-box">
                                    <span>商户号: {{ userInfo.traderMain &&
                                        userInfo.traderMain.trader_code }}</span>
                                </div>
                            </el-dropdown-item> -->
                            <!-- <el-dropdown-item command="setLayout">
                                <div class="dropdown-box">
                                    <span>布局设置</span>
                                    <el-icon :size="20">
                                        <ArrowRight />
                                    </el-icon>
                                </div>
                            </el-dropdown-item> -->
                            <router-link to="/system/configurationCenter/merchant-info">
                                <el-dropdown-item command="selfInfo">
                                    <div class="dropdown-box">
                                        <span>个人信息</span>
                                        <el-icon :size="20">
                                            <ArrowRight />
                                        </el-icon>
                                    </div>
                                </el-dropdown-item>
                            </router-link>
                            <el-dropdown-item command="editPwd">
                                <div class="dropdown-box">
                                    <span>修改密码</span>
                                    <el-icon :size="20">
                                        <ArrowRight />
                                    </el-icon>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item divided command="logout">
                                <span class="text-danger">退出登录</span>
                            </el-dropdown-item>

                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ElMessageBox } from "element-plus"
import LanguageChose from '@/components/LanguageChose'
import SizeSelect from '@/components/SizeSelect'
// import Screenfull from '@/components/Screenfull'
import RuoYiQut from '@/components/RuoYi/Qut'
import usePermissionStore from '@/store/modules/permission';
import useAppStore from '@/store/modules/app';
import { computed, getCurrentInstance, onBeforeUnmount, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useUserStore from '@/store/modules/user'
import { useFullscreen } from '@vueuse/core'

const emits = defineEmits(['setLayout'])

const { proxy } = getCurrentInstance();

const appStore = useAppStore()
const userStore = useUserStore();
const userInfo = computed(() => {
    console.log(1111111111111,userStore.userInfo)
    if (Object.keys(userStore.userInfo).length > 0) {
        return userStore.userInfo
    } else {
        return sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo'))
    }
});

const permission = usePermissionStore();
const { isFullscreen } = useFullscreen();
const router = useRouter();
const isDevlopment = import.meta.env.VITE_APP_ENV == 'development'

const gloablSearch = ref("");
const showSearchBox = ref(false);
const showHistory = ref(false);
const result = reactive({
    resultList: [],
    history: [
        { label: "1" },
        { label: "2" },
        { label: "3" }
    ], // 历史记录
});
const timer = ref(null);

// 拉平路由 拼装搜索
const allMenuList = computed(() => {
    let arr = [];
    permission.addRoutes.forEach((item, index) => {
        let str = item.meta.title + '-';
        let menu_route = item.path;
        if (Array.isArray(item.children) && item.children.length > 0) {

            item.children.forEach((_item) => {
                let secendStr = str + _item.meta.title + "-";
                let secendRouter = menu_route + "/" + _item.path;

                if (Array.isArray(_item.children) && _item.children.length > 0) {

                    _item.children.forEach((__item) => {
                        arr.push({
                            title: secendStr + __item.meta.title,
                            route: secendRouter + "/" + __item.path
                        })
                    })
                }
            })
        }
    })
    return arr
});

const golbalValue = ref(sessionStorage.getItem('api'));
const showClear = computed(() => {
    return gloablSearch.value.length > 0
})


onBeforeUnmount(() => {
    if (timer.value) clearTimeout(timer.value);
})

function globalApiChange(e) {
    sessionStorage.setItem("api", e);
    proxy.$tab.refreshPage()
}

function handleCommand(command) {
    switch (command) {
        case "setLayout":
            setLayout()
            break;
        case "editPwd":
            jumpToProfile()
            break;
        case "logout":
            logout()
            break;
        default:
            break;
    }
}

function inputFocus() {
    if (result.history.length != 0) {
        showSearchBox.value = true;
        showHistory.value = true;
    }
    if (gloablSearch.value == 0) {
        result.resultList = [];
    }
}

function inputBlur() {
    setTimeout(() => {
        showSearchBox.value = false;
    }, 200);
}

function inputSearch(e) {
    if (!e.trim()) {
        result.resultList = []
        return;
    }
    if (timer.value) clearTimeout(timer.value);
    timer.value = setTimeout(() => {
        result.resultList = allMenuList.value.filter(item => {
            return item.title.indexOf(e) != -1
        })
    }, 500)

}

function clearGloablSearch() {
    gloablSearch.value = ""
}

function jumpToMenu(item) {
    router.push({
        path: item.route
    })
    showClear.value = false;
}

function jumpToProfile() {
    router.push({
        path: '/system/configurationCenter/merchant-info',
        query: { table: "resetPwd" }
    })
}

function logout() {
    ElMessageBox.confirm('确定退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {

        userStore.logOut().then(() => {
            // 清空路由
            proxy.$tab.closeAllPage()
            permission.setSidebarRouters([]);
            sessionStorage.clear();
            router.replace("/login")
        })
    }).catch(() => { });
}
function toHome() {
    router.replace("/index")
}
function setLayout() {
    emits('setLayout');
}

</script>

<style lang="scss" scoped>
.dropdown-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 44px;
        height: 44px;
    }

    .dropdown-box-info {
        display: flex;
        flex-direction: column;
    }
}

.head-menu {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #2B2E37;
    padding: 0 20px;




    .logoImg {
        width: 138px;
        height: 32px;
        margin-right: auto;
        cursor: pointer;
    }

    .head-menu-box {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 10px;

        .menu-item {
            font-size: 18px;
            padding: 5px 20px;
            cursor: pointer;
            border-radius: 4px;
            flex-shrink: 0;

            .head-icon {
                font-size: 16px;
                margin-right: 5px;
            }
        }

        .not-active {
            &:hover {
                background: rgba($color: #fff, $alpha: .3);
            }
        }

        .active {
            background: #FF5722;
        }
    }

    .right-menu {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 20px;
            color: #ffffff;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            .avatar-wrapper {
                // margin-top: 5px;
                color: #fff;

                .user-avatar {
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    border: 1px solid #ccc;
                    display: block;
                    margin-right: 5px;
                }

                i {
                    margin-left: 5px;
                    cursor: pointer;
                    font-size: 14px;
                }
            }
        }

        .input-search {
            position: relative;
            margin: 0 10px;


            .global-search {

                :deep(.el-input-group__append,
                    .el-input-group__prepend) {
                    padding: 0 5px;
                    background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
                }

                :deep(.el-input__wrapper.is-focus) {
                    box-shadow: none !important;
                }

                .circleClose {
                    cursor: pointer;

                    &:hover {
                        color: var(--el-color-primary);
                    }
                }
            }


            .search-box {
                position: absolute;
                top: 30px;
                left: 0;
                width: 216px;
                max-height: 500px;
                // max-height: 216px;
                box-sizing: border-box;
                background: #fff;
                border-radius: 4px;
                box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
                padding: 8px;
                overflow-y: scroll;

                .title {
                    color: #666;
                    font-size: 12px;
                    font-weight: bolder;
                    cursor: pointer;
                    line-height: 26px;
                }

                .item {
                    line-height: 24px;
                    font-size: 12px;
                    cursor: pointer;

                    &:hover {
                        background: rgba($color: #000000, $alpha: .05);
                    }
                }
            }
        }


    }
}
</style>